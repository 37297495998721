<template>
  <a-modal :visible="dialogVisible" v-loading="editDialogLoading" @cancel="editDialogClose"
           :mask-closable="false" width="690px" title="添加关键词组">
    <div>
      <a-form layout="horizontal" label-align="right" :model="editForm" :rules="editRules" ref="editFormRef">

        <a-form-item has-feedback label="关键词组名称" name="name">
          <a-input disabled
                   v-model:value="editForm.name" placeholder="请输入关键词组名称"></a-input>
        </a-form-item>

        <a-form-item has-feedback label="状态" label-align="right" name="status">
          <a-radio-group v-model:value="editForm.status">
            <a-radio :value="0">正常</a-radio>
            <a-radio :value="1">禁用</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="报备话术" name="template">
          <div class="">{{ template || '--' }}</div>
          <!--          <div class="m3-flex m3-align-items-start">-->
          <!--            <div class="m3-flex-1">报备话术：</div>-->
          <!--            <div class="m3-flex-8">{{ template || '&#45;&#45;' }}</div>-->
          <!--          </div>-->
        </a-form-item>

        <a-form-item label="关键词" name="keyword">
          <a-input v-model:value="keyword" placeholder="请输入敏感词后回车"
                   @pressEnter="addKeyword"/>
        </a-form-item>
        <a-form-item label="关键词设置规则：">
          <div>
            <p>1、单个关键词输入后，按回车键保存（装修）+ 回车键 </p>
            <p>2、多个关键词请使用英文状态下“,”分割（贷款,利率,装修）+ 回车键</p>
            <p>3、组合关键词请使用“|”分割（贷款|利率|装修,教育|培训|幼儿）</p>
          </div>
          <div style="height: 200px;overflow: auto;border: 1px solid #e4e4e4;padding: 10px">
            <a-tag style="margin-bottom: 10px" @close="deleteKeyword(item)" closable
                   v-for="(item, index) in editForm.keywords" :key="item">{{ item }}
            </a-tag>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <template #footer>
            <span class="dialog-footer">
              <a-button @click="editDialogClose">取消</a-button>
              <a-button type="primary" @click="editDialogConfirm" :loading="editDialogLoading"
                        class="md-primary m3-border-radius-s">添加</a-button>
            </span>
    </template>
  </a-modal>
</template>

<script>
import {computed, onMounted, reactive, ref, toRaw, toRefs, watch} from "vue";
import {ElMessage} from "element-plus";
import {industryAdd, queryById} from "@/api/settings/industry";
import industryRequest from "@/api/settings/industry";
import _lineService from "@/api/open/LineQualityInspections";
import {message} from "ant-design-vue";
import deepClone from "@/utils/deepClone";

export default {
  name: "addIndustryKeyDialog",
  emits: ['update:dialogVisible', 'confirm'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    },
    industry: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    let {industry} = toRefs(props);
    let editFormRef = ref(null);
    let editDialogLoading = ref(false);


    let keyword = ref(null);//编辑keywords时的输入框，接口不需要
    let template = ref(null);//行业话术，仅展示用
    let editForm = reactive({
      id: '',
      uid: '',
      name: '',
      risk: '',
      status: 0,//0：正常；1：禁用
      type: 0,//1-违规关键词 0-线路行业关键词 2-报备话术关键词
      // keyword: '',//编辑keywords时的输入框，接口不需要
      keywords: [],
      industryId: '',
      unIndustryId: '',
      template: null,//报备话术
    });

    let queryKeyByIndustry = async () => {
      if (!editForm.industryId) {
        return;
      }
      try {
        let res = await _lineService._ruleService.queryByIndustry({industryId: editForm.industryId})
        // console.log('queryKeyByIndustry', res)
        if (res && res.code == 200 && res.data) {
          // let editForm = reactive({
          //   id: '',
          //   uid: '',
          //   name: '',
          //   risk: '',
          //   status: 0,//0：正常；1：禁用
          //   type: 0,//1-违规关键词 0-线路行业关键词 2-报备话术关键词
          //   // keyword: '',//编辑keywords时的输入框，接口不需要
          //   keywords: [],
          //   industryId: '',
          // });
          let keyObj = res.data.length > 0 ? res.data[0] : {};

          editForm.id = keyObj?.id;
          editForm.uid = keyObj?.uid;
          editForm.risk = keyObj?.risk;
          editForm.status = keyObj?.status;
          let keywords = (Array.isArray(keyObj?.keywords) ? keyObj?.keywords : keyObj?.keywords?.split(',')) || [];
          editForm.keywords = keywords;
          // console.log('queryKeyByIndustry keywords', editForm.keywords);
        }
      } catch (e) {
        console.log('queryKeyByIndustry err', e.message || e.msg);
      }
    }
    let queryIndustryById = async () => {
      if (!editForm.industryId) {
        return;
      }
      try {
        let res = await industryRequest.queryById({id: editForm.industryId});
        template.value = res?.data?.template;
      } catch (e) {
        console.log('queryIndustryById err', e.message || e.msg);
      }
    }

    watch(industry, async (newV, preV) => {
      // console.log('industry', newV, preV)
      if (newV) {
        editForm.name = newV.name;
        template.value = newV.template;
        if (editForm.industryId != newV.id) {
          // console.log('editForm.industryId != newV.industryId')
          editForm.industryId = newV.id;
          //查询关键词组信息
          await queryKeyByIndustry();
          //查询行业话术
          await queryIndustryById();
        }
      }
    }, {immediate: true, deep: true})

    let editRules = {
      status: [
        {required: true, type: 'number', message: '请选择状态', trigger: 'blur'}
      ],
      keywords: [
        {required: true, message: '请输入关键词', trigger: 'blur'}
      ],
    }


    let addKeyword = () => {
      if (!keyword.value) {
        return;
      }
      if (!editForm.keywords) {
        editForm.keywords = [];
      }
      if (keyword.value.indexOf(',') !== -1) {
        let keywordsSplit = keyword.value
            .split(',')
            .map(item => item.trim())
            .filter(item => item && item.length > 0)
            .filter(item => !editForm.keywords.find(keyword => keyword == item));
        editForm.keywords = editForm.keywords.concat(keywordsSplit);
      } else {
        if (!!keyword.value.trim() && !editForm.keywords.find(item => item == keyword.value.trim())) {
          editForm.keywords.push(keyword.value.trim())
        }
      }
      keyword.value = '';
    }

    let deleteKeyword = (item) => {
      let findIndex = editForm.keywords?.findIndex(keyword => keyword == item);
      if (findIndex != -1) {
        editForm.keywords.splice(findIndex, 1);
      }
    }
    let editDialogClose = () => {
      context.emit('update:dialogVisible', false);
    }
    let editDialogConfirm = async () => {
      let valid = await editFormRef.value?.validate();
      // console.log('editDialogConfirm', editForm, valid)
      // return;
      if (valid) {
        // console.log('editDialogConfirm valid', valid);
        editDialogLoading.value = true;
        let res;
        const params = deepClone(toRaw(editForm));
        let keywords = (Array.isArray(editForm.keywords) ? editForm.keywords?.join(',') : editForm.keywords) || '';
        params.keywords = keywords;
        try {
          if (params.id) {
            res = await _lineService._ruleService.update(params)
          } else {
            res = await _lineService._ruleService.created(params)
          }
          editDialogLoading.value = false;
          message.success('保存成功');
          editFormRef.value?.resetFields();
          context.emit('update:dialogVisible', false);
          context.emit('confirm', true);
        } catch (e) {
          editDialogLoading.value = false;
          message.error(e.message || e.msg);
        }
      }
    }
    //
    // onMounted(()=>{
    //
    // })
    return {
      editFormRef, editDialogLoading,
      keyword, template, editForm, editRules,
      addKeyword, deleteKeyword,
      editDialogClose, editDialogConfirm,
    }
  },
}
</script>

<style scoped>

</style>